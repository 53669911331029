// the axios instance and types
import http from "../api";
//import { APIResponse } from "../types";

async function getMatch(idTournament, idTeam) {
    return await http.get(`show_team/${idTournament}/${idTeam}`);
}

async function getInfoMatch(idTournament, idTeam) {
    return await http.get(`getInfoForTheAddMatch/${idTeam}/${idTournament}`);
}

async function addMatch(aMatch) {
    return await http.post("add_matches", aMatch);
}

async function getPdfMatch(idMatchFixture, idTournament) {
    return await http.post(`generatePDF/${idTournament}/${idMatchFixture}`,null ,{
        responseType: 'blob',
        headers: {
            ...http.defaults.headers,
            'Content-Type': 'application/json'
        }
    });
}

export default {
    getMatch,
    getInfoMatch,
    addMatch,
    getPdfMatch
};
