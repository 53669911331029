/* eslint-disable */

import {defineStore} from "pinia";
import {ref} from 'vue';
import {API} from "@/services";
import http from "@/services/api";
import CryptoJS from 'crypto-js';

export const useAuthStore = defineStore("authStore", () => {


    const token = ref();

    async function login(user, password) {
        try {
            const {status, data} = await API.auth.login(user, password)
            if (status === 200) {
                encryptPassword(data.token);
                http.defaults.headers.common['Authorization'] = `Bearer ${decryptPassword(token.value)}`;
                return {
                    success: true,
                    msg: 'OK',
                }
            }

        } catch (error) {
            console.error(error);
            return {
                success: false,
                msg: error,
            }
        }
    }

        function encryptPassword(password) {
            const key = process.env.VUE_APP_SECRET_KEY.toString();
            const ciphertext = CryptoJS.AES.encrypt(password, key);
            token.value = ciphertext.toString();
        }

        function decryptPassword(encryptedPassword) {
            const bytes = CryptoJS.AES.decrypt(encryptedPassword, process.env.VUE_APP_SECRET_KEY.toString());
            return bytes.toString(CryptoJS.enc.Utf8);
        }

        function logout() {
            token.value =  null;
            localStorage.removeItem('__persisted__authStore')
        }

    return {
        token,
        login,
        decryptPassword,
        logout
    }

},{persist: true}
    );