
import {defineStore} from "pinia";
import {ref} from 'vue';
import {API} from "@/services";

export const useTeamStore = defineStore("teamStore", () => {


    const teams = ref([]);
    const teamPlayers = ref([]);


    function initTeams(data) {

        teams.value = data;

    }

    function initTeamPlayer(data) {

        teamPlayers.value = data;

    }

    function addToTeams(aTeam) {
        teams.value.push(aTeam);
    }


    function removeTeam(id) {

        const idx = teams.value.findIndex(s => s.id === id);

        if (idx === -1) return;

        teams.value.splice(idx, 1);

    }

    async function dispatchGetTeams() {
        try {
            const {status, data} = await API.teams.getTeams();
            console.log(data);
            if (status === 200) {
                initTeams(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchGetTeamPlayers(idTeam) {
        try {
            const {status, data} = await API.teams.getTeamPlayers(idTeam);
            if (status === 200) {
                initTeamPlayer(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchCreateTeam(aTeam) {
        try {
            const {status, data} = await API.teams.createTeam(aTeam);
            if (status === 200) {
                addToTeams(data);
                return {
                    success: true,
                    content: data.content,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }


    return {
        teams,
        initTeams,
        removeTeam,
        dispatchGetTeams,
        dispatchGetTeamPlayers,
        dispatchCreateTeam
    };

});