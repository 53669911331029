<template>
  <!-- Tabla de goleadores -->
  <v-col offset-sm="0" offset-md="3" md="6" sm="4">
    <div class="scorers">
      <h2>Goleadores</h2>
      <div v-for="scorer in scorers" :key="scorer.name" class="scorer">
        <v-row >
          <v-col align="left" ><span class="scorer-name">{{ scorer.player_name }}</span></v-col>
          <v-col align="right"><span class="scorer-goals">{{ scorer.goles }} goles</span></v-col>
        </v-row>
        <div class="scorer-team">
          {{ scorer.team_name }}
        </div>
        <hr>
      </div>
    </div>
  </v-col>
</template>
<script>
import {useTournamentStore} from "@/store/tournament";

export default {

  data() {
    return {
      scorers: []
    };
  },
  props: ["idTournament"],
  mounted() {
    this.getGoleadores();
  },
  methods: {
    async getGoleadores() {
      const tournamentStore = useTournamentStore();
      const response = await tournamentStore.getBestScorerOfTournament(this.idTournament);
      this.scorers = response.content;
    }
  }
};
</script>

<style scoped>
.tournament {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.group {
  width: 400px;
  border: 1px solid #ddd;
  padding: 20px;
  margin-left: 200px;
  background-color: #f9f9f9;
  border-radius: 10px;

}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #4CAF50;
  color: white;
}

td {
  font-weight: bold;
}

h2 {
  text-align: center;
  color: #333;
}


.tournament {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.groups {
  flex: 1;
  margin-right: 40px;
}

.group {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.scorers {
  flex: 1;
}

.scorer {
  margin-bottom: 20px;
}

.scorer-info {
  text-align: left;
}

.scorer-name {
  font-size: 20px;
  margin-bottom: 2px;
}

.scorer-team {
  font-size: 14px;
  color: #666;
  margin-top: 0; /* Asegura que no haya margen entre el nombre y el equipo */
  text-align: left;
}

.scorer-goals {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}

.top-scorer .scorer-name {
  font-size: 32px; /* Tamaño más grande para el nombre */
}

.top-scorer .scorer-goals {
  font-size: 32px; /* Tamaño más grande para el número de goles */
}
</style>
