// services/index.js
import teamController from "./team";
import matchController from "./match";
import tournamentController from "./tournament";
import authController from "./auth";

export const API = {
    teams: teamController,
    matchs: matchController,
    tournaments: tournamentController,
    auth: authController
};
