<template>
    <v-row class="tabs">
      <v-col :key="index" v-for="(tab, index) in tabs" align="center">
      <button
          :key="index"
          :class="{ active: selectedTab === index }"
          @click="selectedTab = index"
          class="tab-button"
      >
        {{ tab }}
      </button>
      </v-col>
    </v-row>

    <v-row class="tab-content">
      <!-- Grupos -->
      <v-col v-if="selectedTab === 0" class="groups">
        <div v-if="champion != ''" class="champion">
          <h2>Champion: {{ champion }}</h2>
        </div>
        <br>
        <v-row v-for="(group, index) in groups" :key="index" class="league-table-container">
          <v-toolbar>
            <v-toolbar-title>Grupo: {{ index + 1 }}</v-toolbar-title>
          </v-toolbar>
          <v-data-table
              :key="index"
              density="default"
              :headers="headers"
              :items="group"
              class="custom-header league-table"
          >
            <template v-slot:header="{ props }">
              <thead>
              <tr>
                <th v-for="header in props.headers" :key="header.key" :class="header.class">
                  <span>{{ header.title }}</span>
                </th>
              </tr>
              </thead>
            </template>

            <template v-slot:item="{ item}">
                <tr :key="item.name" :class="{ classified: classified.includes(item.id_team) }">

                  <td>{{ item.name }}</td>
                  <td>{{ item.points }}</td>
                  <td v-if="item.stats">{{ item.stats.pj }}</td>
                  <td v-else>0</td>
                  <td v-if="item.stats">{{ item.stats.partidos_ganados }}</td>
                  <td v-else>0</td>
                  <td v-if="item.stats">{{ item.stats.partidos_empatados }}</td>
                  <td v-else>0</td>
                  <td v-if="item.stats">{{ item.stats.partidos_perdidos }}</td>
                  <td v-else>0</td>
				<td v-if="sport === 'futbol'">
					{{ item.stats ? item.stats.goles : 0 }}
				</td>
				<td v-if="sport === 'futbol'">
					{{ item.stats ? item.stats.goles_contra : 0 }}
				</td>
				<td v-if="sport === 'futbol'">
					{{ item.stats ? item.stats.goles - item.stats.goles_contra : 0 }}
				</td>

              </tr>
            </template>
            <template #bottom></template>
          </v-data-table>
        </v-row>
        <FixturePlayoff
            v-if="this.matches_phase_2.length > 0"
            :idT="this.idT"
            :info="this.info"
            :matches="this.matches_phase_2"
            :champion="this.champion"
        />
      </v-col>

      <!-- Máximos Goleadores -->
      <v-col v-if="selectedTab === 1 && sport === 'futbol'">
        <MaxScorers :idTournament="this.idT"/>
      </v-col>

      <!-- Grilla de Partidos -->
      <v-col v-if="(selectedTab === 2 && sport === 'futbol') || (selectedTab === 1 && sport !== 'futbol')">
        <GrillaMatchesByDate :matches="matches_phase_1"/>
        <GrillaMatchesByDatePlayoff
            v-if="this.matches_phase_2 !== undefined && this.matches_phase_2 !== null"
            :matches="matches_phase_2"
        />
      </v-col>

      <!-- Equipos -->
      <v-col v-if="(selectedTab === 3 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')">
        <ShowTeamsOfTournament :tournamentId="this.idT"/>
      </v-col>
    </v-row>
</template>

<script>
import GrillaMatchesByDate from './GrillaMatchesByDate.vue';
import GrillaMatchesByDatePlayoff from './GrillaMatchesByDatePlayoff.vue';
import MaxScorers from './MaxScorers.vue';
import FixturePlayoff from './FixturePlayoff.vue';
import ShowTeamsOfTournament from './ShowTeamsOfTournament.vue';

export default {
  components: {
    GrillaMatchesByDate,
    MaxScorers,
    GrillaMatchesByDatePlayoff,
    FixturePlayoff,
    ShowTeamsOfTournament
  },
  props: ['idT', 'info', 'matches_phase_1', 'matches_phase_2', 'champion', 'classified', 'sport'],
  data() {
    return {
      selectedTab: 0,
      tabs: [],
      groups: [],
      scorers: [
        {name: 'Player 2', team: 'Team B1', goals: 3},
        {name: 'Player 3', team: 'Team A2', goals: 2},
        {name: 'Player 4', team: 'Team B2', goals: 2},
      ],
      topScorer: {name: 'Fede', team: 'River', goals: 10},
      headers: [
        {
          align: 'left',
          key: 'name',
          title: 'Equipo',
          class: "custom-header",
		width: '100px'
        },
        {
          align: 'left',
          key: 'points',
          title: 'Pts',
		width: '50px'
        },
        {
          align: 'left',
          key: 'partidos_jugadps',
          title: 'PJ',
		width: '50px'
        },
        {
          align: 'left',
          key: 'partidos_ganados',
          title: 'PG',
		width: '50px'
        },      {
          align: 'left',
          key: 'partidos_empatados',
          title: 'PE',
		width: '50px'
        },      {
          align: 'left',
          key: 'partidos_perdidos',
          title: 'PP',
		width: '50px'
        },      {
          align: 'left d-none d-lg-table-cell',
          key: 'goles',
          title: 'GF',
		width: '50px'
        },{
          align: 'left d-none d-lg-table-cell',
          key: 'goles_contra',
          title: 'GC',
		width: '50px'
        },{
          align: 'left',
          key: 'diferencia_goles',
          title: 'DG',
		width: '50px'
        },
      ],
    };
  },
  mounted() {
    console.log("que onda grupo");
    console.log(this.info);
    this.groups = this.info;
	console.log("deporte " + this.sport);

	if (this.sport !== 'futbol'){
		// saco los q tienen lo de los goles , los 3 ultimos elementos 
		this.headers.splice(-3);

	}


	if ( this.sport !== "futbol"){
		this.tabs = ['Grupos', 'Grilla de Partidos', 'Jugadores'];
	}
	else{
		this.tabs = ['Grupos', 'Máximos Goleadores', 'Grilla de Partidos', 'Equipos'];
	}
  },
};
</script>

  <style scoped>
  /* Estilos para las pestañas */
/* Estilos para las pestañas */
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background-color: #0A374D; /* Azul oscuro */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tab-button {
    padding: 12px 24px;
    margin: 0 10px;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
}

.tab-button.active {
    background-color: #14A38B; /* Verde pastel */
    color: white;
    border: 2px solid #14A38B; /* Verde pastel */
}

.tab-button:hover {
    background-color: #096B80; /* Azul intermedio para hover */
}

/* Estilos para el contenido */
.tab-content {
    background-color: #F9F9F9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.group {
    margin-bottom: 20px;
}

/* Estilos para el contenedor de la tabla */
.league-table-container {
    margin-left: -33px ; /* Elimina margen lateral para centrar la tabla */
    padding: 20px;
    background-color: #F9F9F9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 1184px; /* Asegura que el contenedor ocupe todo el ancho */
}

/* Estilos para la tabla */
.league-table {
    width: 100%; /* Asegura que la tabla ocupe todo el ancho del contenedor */
    border-collapse: collapse;
    font-size: 16px;
    margin-top: 10px;
}

.league-table th,
.league-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.league-table th {
    background-color: #0A374D; /* Azul oscuro */
    color: white;
}

.league-table tbody tr:hover {
    background-color: #f1f1f1;
}

.league-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

/* Clase para los equipos clasificados */
.classified {
    background-color: #d4eddd !important; /* Verde pastel */
}

/* Ajustes para el wrapper de la tabla dentro de v-data-table */
.v-data-table >>> .v-data-table__wrapper {
    width: 100%; /* Asegura que el wrapper de la tabla también ocupe todo el ancho */
}

.v-data-table >>> .v-data-table__td {
    background-color: #1976d2 !important; /* Color de fondo deseado */
    color: white; /* Color del texto para mejor contraste */
}

/* Media queries para pantallas pequeñas */
@media (max-width: 600px) {
    /* Ajusta el ancho máximo según tu diseño */
    .tabs {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      background-color: #0A374D; /* Azul oscuro */
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .tab-content {
      margin: 0px;
    }

	.league-table-container {
		width: 100%;
	}

	.v-toolbar, .v-data-table {
		width: 100%;
	}


}

  </style>
