<template>
  <div class="tournament-container">
    <div class="tabs">
      <button
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: selectedTab === index }"
          @click="selectedTab = index"
          class="tab-button"
      >
        {{ tab }}
      </button>
    </div>
    
        <div class="tab-content">
            <div v-if="selectedTab === 0">
                <FixturePlayoff v-if="this.matches.length > 0"
                    :idT="this.idT" :info="this.info" :matches="this.matches" :champion = "this.champion"/>  
            </div>
        
            <div v-if="selectedTab === 1 && sport === 'futbol'">
                <!-- Goleadores -->
                <MaxScorers :idTournament="this.idT" />
            </div>
        
            <div v-if="(selectedTab === 2 && sport === 'futbol') || (selectedTab === 1 && sport !== 'futbol')">
                <!-- Partidos divididos por fecha -->
                <GrillaMatchesByDatePlayoff v-if="this.matches.length > 0"
                    :matches="matches" />
            </div>
            <div v-if="(selectedTab === 3 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')">
                <!-- Partidos divididos por fecha -->
                <ShowTeamsOfTournament :tournamentId="this.idT" />
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import GrillaMatchesByDatePlayoff from './GrillaMatchesByDatePlayoff.vue';    
    import MaxScorers from './MaxScorers.vue';
    import FixturePlayoff from './FixturePlayoff.vue';
    
    import ShowTeamsOfTournament from './ShowTeamsOfTournament.vue';

    
    export default {
    components: {
        MaxScorers,
        FixturePlayoff,
        GrillaMatchesByDatePlayoff,
        ShowTeamsOfTournament
    },
    props: ['idT', 'info', 'matches', 'champion', 'sport'],
    data() {
        return {
            tabs: ['Draw', 'Top Scorers', 'Grilla de partidos', 'Equipos'],
            activeTab: 0,
            groups: [],
            scorers: [],
            topScorer: { name: 'Fede', team: 'River', goals: 10 },
            selectedTab: 0,
        };
    },
    mounted() {
        this.groups = this.info;
        if ( this.sport !== "futbol"){
            this.tabs = ['Draw', 'Grilla de Partidos', 'Jugadores'];
        }
        else{
            this.tabs = ['Draw', 'Máximos Goleadores', 'Grilla de Partidos', 'Equipos'];
        }
    }
    };
    </script>
    
    <style scoped>
    
    .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background-color: #0A374D; /* Azul oscuro */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
        

    .tab-button {
    padding: 12px 24px;
    margin: 0 10px;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
}

.tab-button.active {
    background-color: #14A38B; /* Verde pastel */
    color: white;
    border: 2px solid #14A38B; /* Verde pastel */
}

.tab-button:hover {
    background-color: #096B80; /* Azul intermedio para hover */
}
    </style>
    