<template>
    <div class="team-container">
        <h2>Team: {{ team_name }}</h2>
        <ul v-if="jugadores.length" class="players-list">
            <li v-for="(jugador, index) in jugadores" :key="index" class="player-item">
                {{ jugador.name }} - <span class="position">{{ jugador.position }}</span>
            </li>
        </ul>
        <p v-else class="no-players">No players found for this team.</p>
    </div>
  </template>

<script>
import {useTeamStore} from "@/store/team";

export default {
name: 'ShowTeam',
props: {
    teamId: {
        type: String,
        required: true
    }
},
data() {
    return {
        jugadores: [],
        team_name : ""
    };
},
created() {
    this.fetchTeamDetails();
},
methods: {
    async fetchTeamDetails() {
      const teamStore = useTeamStore();
      const response = await teamStore.dispatchGetTeamPlayers(this.teamId);
      if(response.success){
        this.team_name = response.content.name;
        this.jugadores = response.content.players;
      }

    }
}
};
</script>

<style scoped>
/* General Styles */
.team-container {
    padding: 20px;
    background-color: #f0f4f8; /* Light background */
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    font-family: 'Arial', sans-serif;
}

/* Heading Styles */
h2 {
    color: #2c3e50; /* Matches navbar */
    font-size: 1.8em;
    margin-bottom: 20px;
    font-weight: bold;
}

/* Player List */
.players-list {
    list-style: none;
    padding: 0;
}

.player-item {
    background-color: #ecf0f1; /* Light grey background for each player */
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player-item:hover {
    background-color: #d1e7e1; /* Subtle hover effect */
}

.position {
    color: #1abc9c; /* Matches buttons and highlights */
    font-weight: bold;
}

/* No players message */
.no-players {
    color: #e74c3c; /* Matches the red from the navbar */
    font-size: 1.2em;
}

/* Responsive design */
@media (max-width: 768px) {
    .team-container {
        padding: 15px;
    }

    h2 {
        font-size: 1.5em;
    }

    .player-item {
        flex-direction: column;
        align-items: flex-start;
    }
}
</style>
  