<!-- MenuComponent.vue -->
<template>
  <v-app-bar color="primary" dark v-if="!isMobile" key="desktopMenu">
    <v-avatar size="500" @click="goToHome()" style="width: 250px; height: 250px;">
      <v-img :src="require('../assets/logo.jpeg')" alt="Logo" fit="contain"/>
    </v-avatar>
    <v-btn @click="goToHome()">Home</v-btn>

	<v-btn @click="goToHistoricTournaments()">Torneos Histórico</v-btn>
    <v-btn @click="goToShowTeams()">Equipos</v-btn>
    <v-menu v-if = "is_able" offset-y color="primary">
      <template v-slot:activator="{ props: attrs }">
        <v-btn text="Settings" v-bind="attrs"></v-btn>
      </template>
      <v-list>
        <v-list-item link color="primary">
          <v-list-item-title @click="goToCreateTournament()">
            Crear Torneo
          </v-list-item-title>
        </v-list-item>
        <v-list-item link>
          <v-list-item-title @click="goToAddTeam()">
            Agregar Equipo
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer></v-spacer>
    <v-btn @click="logout">Logout</v-btn>
  </v-app-bar>
  <v-app-bar app color="primary" v-if="isMobile">
    <v-toolbar-title @click="goToHome()" style="cursor: pointer">Torneus</v-toolbar-title>
    <v-app-bar-nav-icon variant="text" @click="toggleDrawer()"></v-app-bar-nav-icon>
  </v-app-bar>
  <v-navigation-drawer
      :location="isMobile?'top':'none'"
      temporary
      v-model="drawer"
      scrim
      expand-on-hover
  >
        <v-list>
          <v-list-item link>
            <v-list-item-title @click="goToHistoricTournaments()">
              Torneos Histórico
            </v-list-item-title>
          </v-list-item>          <v-list-item link>
            <v-list-item-title @click="goToShowTeams()">
              Equipos
            </v-list-item-title>
          </v-list-item>
          <v-list-group value="Configuracion">
            <template v-slot:activator="{ props }">
              <v-list-item
                  v-bind="props"
                  title="Configuración"
                  class="center-with-padding"
              ></v-list-item>
            </template>
            <v-list-item link color="primary">
              <v-list-item-title @click="goToCreateTournament()">
                Crear Torneo
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="goToAddTeam()">
                Agregar Equipo
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item link>
            <v-list-item-title @click="logout">
              Logout
            </v-list-item-title>
          </v-list-item>

        </v-list>
  </v-navigation-drawer>
</template>

<script>
/* eslint-disable */

import {useAuthStore} from "@/store/auth";
import { Slide } from 'vue3-burger-menu';
import axios from 'axios';

export default {
  components: {
    Slide // Register your component
  },
  data() {
    return{
      drawer: false,
      menuVisible: false,
	  is_able : false 

    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
  },
    mounted() {
        this.fetch_able();
    },
  methods: {
	async fetch_able(){
            const token = useAuthStore().token; // Obtener el token del store

            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + 'is_able', {}, {
                headers: {
                    'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                this.is_able = response.data;
            }

        },
    goToHome() {
      this.$router.push({name: 'Tournament'});
    },
    goToHistoricTournaments() {
      this.$router.push({name: 'HistoricTournaments'});
    },
    goToCreateTournament() {
      this.$router.push({name: 'CreateTournament'});
    },
    goToAddTeam() {
      this.$router.push({name: 'AddTeam'});
    },
    logout() {
      useAuthStore().logout();
      this.$router.push({name: 'LoginAdmin'});
    },
    goToShowTeams() {
      this.$router.push({name: 'ShowTeams'});
    },
    handleClick(item) {
      console.log(item)
    },
    toggleDrawer() {
      this.drawer = !this.drawer; // Toggle the drawer state
    },

  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .center-with-padding{
    padding-left: 20% !important;
  }
}
</style>
  