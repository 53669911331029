<template>

  <v-col offset-sm="0" offset-md="3" md="6" sm="4">
    <h2>Teams in Tournament</h2>
    <ul class="team-list">
      <div v-if="existTeams">
        <li v-for="(team, index) in teams" :key="index" @click="goToTeamDetails(team.id)"
            class="team-item">
          {{ team.name }}
        </li>
      </div>
      <li v-if="!existTeams" class="no-teams">cargando...</li>
      <li v-if="existTeams?teams.length===0:false" class="no-teams">Sin equipos.</li>
    </ul>
  </v-col>
</template>

<script>
import MenuComponent from './MenuComponent.vue';
import {useTeamStore} from "@/store/team";
import {useTournamentStore} from "@/store/tournament";

/* eslint-disable */
export default {
  name: 'ShowTeamsOfTournament',
  components: {
    MenuComponent
  },
  props: {
    tournamentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      teams: [],
      menu: false,
      existTeams: false
    };
  },
  created() {
    this.fetchTeams();
  },
  methods: {
    async fetchTeams() {
      if (this.tournamentId == null) {
        this.menu = true;
        const teamStore = useTeamStore();
        const response = await teamStore.dispatchGetTeams();
        this.teams = response.content; // Asumiendo que 'teams' es un array en la respuesta
      } else {
        const tournamentStore = useTournamentStore();
        const response = await tournamentStore.getTeamsOfATournament(this.tournamentId);
        console.log(response);
        this.teams = response.content; // Asumiendo que 'teams' es un array en la respuesta
      }
      this.existTeams = true;
    },
    goToTeamDetails(teamId) {
      this.$router.push({name: 'ShowTeam', params: {teamId: teamId}});
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.team-list {
  list-style-type: none;
  padding: 0;
}

.team-item {
  background-color: #f9f9f9;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.team-item:hover {
  background-color: #e0e0e0; /* Color de fondo más claro al pasar el cursor */
  color: #333; /* Color de texto más oscuro al pasar el cursor para contraste */
}

.no-teams {
  text-align: center;
  font-style: italic;
}
</style>
  