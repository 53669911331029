<template>
    <div class="main-container">
        <div class="tournaments-container">
          <div v-if = "is_able" class="tournament-card" @click="goToCreateTournament">
            <div class="tournament-header">
              <h2>Agregar torneo</h2>
            </div>
            <div class="tournament-details" align="center">
              <v-img :src="require('../assets/plus-add.png')" :width="100"
                     aspect-ratio="4/3"
                     cover/>
            </div>
          </div>
            <div v-for="tournament in tournaments" :key="tournament.id" class="tournament-card" @click="goToTournament(tournament.id)">
                <div class="tournament-header">
                    <h2>{{ tournament.name_of_tournament }}</h2>
                </div>
                <div class="tournament-details">
                    <p>Deporte : <b> {{ tournament.sport }} </b></p>
                    <p>{{ tournament.date_begin }}</p>
                    <p>{{ tournament.schema_value }}</p>
                    <p v-if="tournament.champion != ''">{{ tournament.champion }}</p>
                    <p>Fase actual : {{ tournament.current_phase }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useTournamentStore} from "@/store/tournament";
import axios from 'axios';
import {useAuthStore} from "@/store/auth";


export default {
        components: {
        },
        data() {
            return {
                tournaments: [
                ],
                is_able : false 

            };
        },
        mounted() {
            // Aquí simulas la obtención de datos, podrías hacer una llamada a la API
            this.fetchTournamentData();
            this.fetch_able();
        },
        methods: {
            async fetch_able(){
                const token = useAuthStore().token; // Obtener el token del store
                const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + 'is_able', {}, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 200) {
                    this.is_able = response.data;
                }

            },
            goToTournament(id) {
                // Redirigir a la página de detalles del torneo
                this.$router.push({ name: 'TournamentDetails', params: { id } });
            },
            goToCreateTournament() {
                // Redirigir a la página de creación de torneos
                this.$router.push({ name: 'CreateTournament' });
            },
            goToAddTeam() {
                // Redirigir a la página de agregar equipo
                this.$router.push({ name: 'AddTeam' });
            },
            goToHistoricTournaments() {
                // Redirigir a la página de agregar equipo
                this.$router.push({ name: 'HistoricTournaments' });
            },
            goToHome() {
                // Redirigir a la página de agregar equipo
                this.$router.push({ name: 'Home' });
            },
            logout() {
                // Eliminar el token de localStorage
                localStorage.removeItem('token');

                // Redirigir al usuario a la página de login
                this.$router.push({ name: 'Login' });
            } ,


            async fetchTournamentData() {
              const tournamentStore = useTournamentStore();
              const response = await tournamentStore.dispatchGetTournaments();
              if(response.success){
                this.tournaments = response.content;
              }else{
                console.error("Error al obtener los datos del fixture:", response.error);
              }
            }
        },
    };
</script>

<style scoped>
    .main-container {
        font-family: 'Arial', sans-serif;
        margin-top: 2%;
    }

    .navbar {
        background-color: #2c3e50;
        color: #ecf0f1;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-logo {
        font-size: 1.5em;
    }

    .nav-links {
        list-style: none;
        display: flex;
        gap: 20px;
    }

    .nav-links a {
        color: #ecf0f1;
        text-decoration: none;
        font-size: 1.1em;
    }

    .settings-dropdown {
        position: relative;
    }

    .settings-dropdown .dropdown-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #34495e;
        padding: 10px 0;
        border-radius: 4px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    }

    .settings-dropdown:hover .dropdown-menu {
        display: block;
    }

    .dropdown-menu li {
        padding: 10px 20px;
        cursor: pointer;
        color: #ecf0f1;
    }

    .dropdown-menu li:hover {
        background-color: #1abc9c;
    }

    .tournaments-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px;
    }

    .tournament-card {
        background-color: #f4f4f4;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s;
        cursor: pointer;
        width: 300px;
    }

    .tournament-card:hover {
        transform: scale(1.05);
    }

    .tournament-header {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .tournament-details {
        font-size: 1em;
        color: #7f8c8d;
    }
</style>
