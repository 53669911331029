<template>
  <v-container class="tournament-body">
    <h1>{{ tournament.name }}</h1>
    <v-row class="phase-info">
      <v-col>
      <div v-for="(p, index) in phase" :key="index" class="phase">
        <span class="phase-number">Fase {{ index + 1 }}: </span>
        <span class="phase-name">{{ p }}</span>
      </div>
      </v-col>
    </v-row>
    <!-- Puedes agregar más detalles aquí -->
    <FixtureGroup v-if="phase[0] == 'group'" :idT="this.id" :info="this.tournament[0]"
                  :matches_phase_1="this.matches_phase_1" :matches_phase_2="this.matches_phase_2"
                  :champion=this.champion :classified="this.classified" :sport = "this.sport"/>
    <FixtureLeague v-if="phase[0] == 'league'" :idT="this.id" :info="this.tournament[0]"
                   :matches_phase_1="this.matches_phase_1" :matches_phase_2="this.matches_phase_2"
                   :champion=this.champion :classified=this.classified :tab="indexTab" :sport = "this.sport"/>
    <ShowingPlayoffTabs v-if="phase[0] == 'playoff' && matches_phase_1.length > 0" :idT="this.id"
                        :info="this.tournament[0]"
                        :matches="this.matches_phase_1" :champion="this.champion" :sport = "this.sport"/>

  </v-container>
</template>

<script>

import FixtureGroup from './FixtureGroup.vue';
import FixtureLeague from './FixtureLeague.vue';
import ShowingPlayoffTabs from './ShowingPlayoffTabs.vue';
import {useTournamentStore} from "@/store/tournament";
import { useRouter } from 'vue-router';

export default {
  components: {
    FixtureGroup,
    FixtureLeague,
    ShowingPlayoffTabs,
  },
  props: ['id'],
  data() {
    return {
      tournament: [],
      phase: [],
      matches_phase_1: [],
      matches_phase_2: [],
      champion: "",
      classified: [],
      indexTab: 1, 
		sport : "futbol"
    };
  },
  mounted() {
    // Aquí simulas la obtención de datos, podrías hacer una llamada a la API
    this.fetchTournamentData();
    this.getMatches();
    const router = useRouter();
    this.indexTab = router.currentRoute.value.query.tab;
  },
  methods: {
    async fetchTournamentData() {
      try {
        const tournamentStore = useTournamentStore();
        const response = await tournamentStore.dispatchATournament(this.id);
        const arrayData = response.content;
        console.log(arrayData);
        arrayData.forEach((element, index) => {
          index = index + 1
          this.phase.push(element["phase_" + index]["schema"]);
          this.tournament.push(element["phase_" + index]["positions"]);
          this.champion = element["phase_" + index]["champion"];
			this.sport = element["phase_" + index]["sport"];
          if (index == 1) {
            this.classified = element["phase_" + index]["classified"];
          }
        });

      } catch (error) {
        console.error("Error al obtener los datos del fixture:", error);
      }
    },
    async getMatches() {
      try {
        const tournamentStore = useTournamentStore();
        var response2 = await tournamentStore.getMatchesOfATournament(this.id); // URL de tu API local
        response2 = response2.content;
        this.matches_phase_1 = response2.phase_1;
        if (response2.phase_2 !== undefined) {
          this.matches_phase_2 = response2.phase_2;
        }

      } catch (error) {
        console.error("Error al obtener los datos del fixture:", error);
      }
    },
    logout() {
      // Eliminar el token de localStorage
      localStorage.removeItem('token');

      // Redirigir al usuario a la página de login
      this.$router.push({name: 'Login'});
    }
  }
};
</script>


<style scoped>
.tournament-header {
  padding: 20px;
  background-color: #f5f5f5;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}


.phase-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.phase {
  margin: 0 20px;
  text-align: center;
}

.phase-number {
  font-size: 16px;
  font-weight: bold;
  color: #007bff; /* Azul suave para destacar */
}

.phase-name {
  font-size: 18px; /* Tamaño ligeramente mayor para más impacto */
  font-weight: 600; /* Negrita */
  color: #333;
  background-color: #e0f7fa; /* Fondo sutil */
  padding: 5px 10px; /* Espaciado interno */
  border-radius: 4px; /* Bordes redondeados */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  display: inline-block; /* Para que el fondo y el padding se apliquen correctamente */
}
@media (max-width: 600px) {
  .tournament-body {
    padding: 0;
  }

  .phase-info {
    margin: 0;
  }
}
</style>