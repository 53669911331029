<template>
  <v-col offset-sm="0" offset-md="3" md="6" sm="4" class="tournament-matches">
        <div v-for="(matchesByDate, index) in matches" :key="index" class="date-group">
            <h2 class="date-title">Fecha: {{ index }}</h2>
            <div v-for="(matchesByGroup, index2) in matchesByDate" :key="index2" class="group-section">
                <h3 class="group-title">Grupo: {{ index2 }}</h3>
                <div v-for="(match, index3) in matchesByGroup" class="match" :key="index + index3" :style="{ backgroundColor: isHovered === (index3+index) && match.result ? '#90CAF9' : '#fff' }"
                     @mouseover="isHovered = index3 + index"
                     @mouseleave="isHovered = null"
                >
                    <div class="teams">
                        <div v-if="match.result && is_able" @click="gotoMatch(match.id, match.id_tournament)" style="cursor: pointer;" class="margin-for-mobile match-info-2">
                            <span> {{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>
                        <div v-else-if="match.result && !is_able" @click="gotoMatch(match.id, match.id_tournament)" style="cursor: pointer;" class="margin-for-mobile match-info">
                            <span> {{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>
                        <div v-else-if="!match.result && is_able" class="match-info-3 margin-for-mobile">
                            <span>{{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>
                        <div v-else class="match-info margin-for-mobile">
                            <span>{{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>
                      <div class="match-actions-result" v-if="match.result">
                        <span class="result">{{ match.result.replace(/\s/g, '') }}</span>
                      </div>
                      <div class="match-actions" v-if="!match.result && is_able">
                        <button v-if="!match.result && is_able" type="button" class="btn-planilla" @click="getPlanilla(match.id, match.id_tournament)">Abrir planilla</button>
                        <router-link v-if="!match.result && is_able" :to="{ name: 'matchResult', params: { matchId: match.id, tournamentId: match.id_tournament }}">
                          <button type="button" class="btn-planilla" >Cargar partido</button>
                        </router-link>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </v-col>
</template>

<script>
import {useMatchtore} from "@/store/match";
import axios from 'axios';
import { useAuthStore } from "@/store/auth";

export default {
  data() {
    return {
      isHovered: null,
      is_able : false 
    };
  },
    props: {
        matches: {
            type: Object,
            required: true
        }
    },
    mounted(){
        this.fetch_able();
    },
    methods: {
        async fetch_able(){
            const token = useAuthStore().token; // Obtener el token del store

            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + 'is_able', {}, {
                headers: {
                    'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                this.is_able = response.data;
            }

        },
        gotoMatch(idMatchFixture, id_tournament) {
            this.$router.push({ name: 'showMatch',
                params: { "tournamentId": id_tournament, "matchId": idMatchFixture } });
        },
        async getPlanilla(idMatchFixture, id_tournament) {
            try {
              const matchStore = useMatchtore();
              //const response =  API.matchs.getPdfMatch(idMatchFixture, id_tournament);
              const response =  matchStore.dispatchPdfMatch(idMatchFixture, id_tournament);

              // Creamos un URL a partir del archivo binario recibido
              const url = window.URL.createObjectURL(new Blob([(await response).content]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'match_planilla.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error("There was an error generating the PDF:", error);
            }
        }        
    }
};
</script>

<style scoped>
.tournament-matches {
    font-family: Arial, sans-serif;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
}

.date-group {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.date-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #007bff; /* Cambiado a un azul más suave */
    font-weight: bold;
}

.group-section {
    margin-bottom: 15px;
}

.group-title {
    font-size: 20px;
    margin-bottom: 8px;
    color: #333;
}

.match {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.teams {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.match-info {
    flex: 1;
    text-align: center;
    font-size: 16px;
    margin-left: 0px;
}

.match-info-2 {
    flex: 1;
    text-align: center;
    font-size: 16px;
    margin-left: 40px;
}

.match-info-3 {
    flex: 1;
    text-align: center;
    font-size: 16px;
    margin-left: 100px;
}

.match-actions {
    display: flex;
    flex-direction: column;
}

.match-actions-result {
  display: flex;
  flex-direction: column;
  width: 5.6% !important;
  margin-right: 20px;
}

.result {
    font-weight: bold;
    color: green;
    width: 40px ;
    margin-right: 20px;
}

.upcoming {
    color: #ff5722; /* Naranja */
}

.btn-planilla {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
}

.btn-planilla:hover {
    background-color: #0056b3;
}


.margin-for-mobile{
    margin-left : 9%;
}

</style>
