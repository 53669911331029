<template>
      <v-col offset-sm="0" offset-md="3" md="6" sm="4" class="tournament-matches">
    <div>
        <h1>Playoffs</h1>

        <div v-for="(match, index) in matches" :key="index" class="match">
            <!-- Insertar una separación antes de los partidos de cada nueva ronda -->
            <div v-if="shouldShowSeparator(index)">
                <div class="round-separator">
                    <span><h2>--- {{ getRoundName(index) }} ---</h2></span>
                    <hr>
                </div>
            </div>

            <div class="teams">
                <div v-if="match.result && is_able" @click="gotoMatch(match.id, match.id_tournament)" style="cursor: pointer;" class="match-info-4">
                            <span> {{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>
                        <div v-else-if="match.result && !is_able" @click="gotoMatch(match.id, match.id_tournament)" style="cursor: pointer;" class="match-info">
                            <span> {{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>
                        <div v-else-if="!match.result && is_able && (match.name_team_1 != 'BYE' && match.name_team_2 != 'BYE') && 
                            (match.name_team_1 != '' && match.name_team_2 != '') && 
                            (match.name_team_1 != 'Proximamente' && match.name_team_2 != 'Proximamente')" class="match-info-3">
                            <span>{{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>
                        <div v-else class="match-info-2">
                            <span>{{ match.name_team_1 }} vs {{ match.name_team_2 }}</span>
                        </div>  

                    <div  
                        v-if = " (match.name_team_1 != 'BYE' && match.name_team_2 != 'BYE') && 
                            (match.name_team_1 != '' && match.name_team_2 != '') && 
                            (match.name_team_1 != 'Proximamente' && match.name_team_2 != 'Proximamente') " 
                            class="match-actions-result">
                        </div>
                    <div class="match-actions-result" v-if="match.result">
                        <span class="result">{{ match.result.replace(/\s/g, '') }}</span>
                        </div>                    
                        <div class="match-actions" v-if="(match.name_team_1 != 'BYE' && match.name_team_2 != 'BYE') && 
                            (match.name_team_1 != '' && match.name_team_2 != '') && 
                            (match.name_team_1 != 'Proximamente' && match.name_team_2 != 'Proximamente')  && is_able">
                            <button v-if="!match.result && is_able" type="button" class="btn-planilla" @click="getPlanilla(match.id, match.id_tournament)">Abrir planilla</button>
                            <router-link v-if="!match.result && is_able" :to="{ name: 'matchResult', params: { matchId: match.id, tournamentId: match.id_tournament }}">
                                    <button type="button" class="btn-planilla" >Cargar partido</button>
                            </router-link>
                        </div>


            </div>
        </div>
    </div>
</v-col>

</template>

<script>
import {useMatchtore} from "@/store/match";
import axios from 'axios';
import { useAuthStore } from "@/store/auth";

export default {
    data() {
    return {
      isHovered: null,
      is_able : false 

    };
  },
  mounted() {
        console.log("aca grilla ");
        console.log(this.matches);
        this.fetch_able();

    },
    props: {
        matches: Array,
    },
    computed: {
        rounds() {
            const totalMatches = this.matches.length;
            const rounds = [];
            let matchesInRound = totalMatches;
            // Itera y divide por 2 en cada ronda hasta llegar a 1 partido (la final)
            while (matchesInRound > 1) {
                matchesInRound = Math.ceil(matchesInRound / 2);  // Redondeo hacia arriba para asegurar enteros
                rounds.push(matchesInRound);
            }
            // Añadir la final como la última ronda
            rounds.push(1);
            console.log("rondas");
            console.log(rounds);
            return rounds;
        }, 
        roundNames(){
            if (this.matches.length == 7){
                return ["Cuartos", "Semis", "Final"];
            }
            if (this.matches.length == 15){
                return ["Octavos", "cuartos", "semis", "final"];
            }
            if (this.matches.length == 31){
                return ["primera ronda", "octavos", "cuartos", "semis", "final"];
            }
            if (this.matches.length == 63){
                return ["primera ronda","segunda ronda",  "octavos", "cuartos", "semis", "final"];
            }
            return ["cuartos", "semis", "final"];
        }
    },
    methods: {
        async fetch_able(){
            const token = useAuthStore().token; // Obtener el token del store

            const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + 'is_able', {}, {
                headers: {
                    'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                this.is_able = response.data;
            }

        },
        shouldShowSeparator(index) {
            let cumulativeMatches = 0;

            if ( index === 0) // Para el primer partido de la primera ronda
                return true; 

            for (let i = 0; i < this.rounds.length; i++) {
                cumulativeMatches += this.rounds[i];
                if (index === cumulativeMatches) {
                    return true;
                }
            }
        
        },
        getRoundName(index) {
            let cumulativeMatches = 0;

            for (let i = 0; i < this.rounds.length; i++) {
                cumulativeMatches += this.rounds[i];
                if (index < cumulativeMatches) {
                    return this.roundNames[i];
                }
            }
            console.log("partidos");
            console.log(this.matches);
            return '';
        }
        , 
        gotoMatch(idMatchFixture, id_tournament){
            this.$router.push({ name: 'showMatch', 
                params: { "tournamentId":id_tournament , "matchId":idMatchFixture } });
        },
        async getPlanilla(idMatchFixture, id_tournament){
            try {
                // Hacemos la solicitud POST usando async/await
              const matchStore = useMatchtore();
              //const response =  API.matchs.getPdfMatch(idMatchFixture, id_tournament);
              const response =  matchStore.dispatchPdfMatch(idMatchFixture, id_tournament);

              // Creamos un URL a partir del archivo binario recibido
              const url = window.URL.createObjectURL(new Blob([(await response).content]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'match_planilla.pdf'); // Nombre del archivo a descargar
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Removemos el enlace después de la descarga

            } catch (error) {
                // Manejo de errores
                console.error("There was an error generating the PDF:", error);
            }
        }         
    }
};
</script>


<style scoped>
.tournament-matches {
    font-family: Arial, sans-serif;
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
}

.date-group {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.date-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #007bff; /* Cambiado a un azul más suave */
    font-weight: bold;
}

.group-section {
    margin-bottom: 15px;
}

.group-title {
    font-size: 20px;
    margin-bottom: 8px;
    color: #333;
}

.match {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.teams {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.match-info {
    flex: 1;
    text-align: center;
    font-size: 16px;


}



.match-actions {
    display: flex;
    flex-direction: column;
}

.match-info-3 {
    flex: 1;
    text-align: center;
    font-size: 16px;
    margin-left: 200px;
}
.result {
    font-weight: bold;
    color: green;
    width: 40px ;
}

.upcoming {
    color: #ff5722; /* Naranja */
}

.match-info-2 {
    flex: 1;
    text-align: center;
    font-size: 16px;
    margin-left: 30px;
}
.match-info-4 {
    flex: 1;
    text-align: center;
    font-size: 16px;
    margin-left: 140px;
}


.btn-planilla {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
}

.btn-planilla:hover {
    background-color: #0056b3;
}
.match-actions-result {
    display: flex;
    flex-direction: column;
    width: 5.6% !important;
    margin-right: 20px;
}
</style>
