// the axios instance and types
import http from "../api";
//import { APIResponse } from "../types";

async function getTeams() {
    return await http.get("team");
}

async function deleteTeam(id) {
    return await http.delete(`team/${id}`);
}

async function updateTeam(input) {
    return await http.put("team", input);
}

async function getTeamPlayers(id) {
    return await http.get(`show_team/${id}`);
}

async function createTeam(aTeam) {
    return await http.post("team", aTeam);
}


export default {
    getTeams,
    getTeamPlayers,
    updateTeam,
    deleteTeam,
    createTeam
};
