<template>
  <v-container class="padding-left-sm">
    <v-row>
      <v-col class="padding-left-sm" offset-sm="0" offset-md="3" sm="12" md="6" lg="6">
        <v-card elevation="5">
          <v-card-item>
            <v-card-title>Completar resultado de equipos</v-card-title>
            <v-card-subtitle>Partido de <b>{{ local }}</b> vs <b>{{ visitante }}</b>, fecha Nro {{ fechaNro }}
            </v-card-subtitle>
          </v-card-item>
          <v-card-text v-if="isLocal">
            <v-row>
              <v-col md="2" sm="6" class="align-start" align="right">Equipo a completar:</v-col>
              <v-col sm="2" align="left"><b>{{ local }}</b></v-col>
            </v-row>
            <v-row>
              <v-col md="2" sm="6" class="align-start col-padding-top" align="right">Goles de equipo:</v-col>
              <v-col sm="1" md="2" align-self="center">
                <VInlineSelect
                    v-model="formattedGoalsTeamA"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    name="nro"
                    empty-text="sin datos"
                    return-object
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="!isLocal">
            <v-row>
              <v-col md="2" sm="6" class="align-start col-padding-top" align="right">Equipo a completar:</v-col>
              <v-col sm="2" align-self="start"><b>{{ visitante }}</b></v-col>
            </v-row>
            <v-row>
              <v-col md="2" sm="6" class="align-start" align="right" style="padding-top: 1.7%;">Goles de equipo:</v-col>
              <!--          <v-col ><v-text-field variant="underlined" hide-details :rules="[v => !!v || 'Field is required']" style="height: 10%; min-height: 20px;" dense ></v-text-field></v-col>-->
              <v-col sm="1">
                <VInlineSelect
                    v-model="formattedGoalsTeamB"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    empty-text="sin datos"
                    name="nro"
                    return-object
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
              :key="tableOptions.tableKey"
              density="default"
              :headers="headers"
              :items="localTeam"
              :loading="tableOptions.loading"
              hide-footer
              v-if="localTeam !== '' && isLocal"
          >

            <template v-slot:item="{ item, index}">
              <tr :class="{ 'even-row': index % 2 === 0 }">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.goal"
                      item-title="nro"
                      item-value="abbr"
                      empty-text="sin datos"
                      :items="optionsNumberGoals"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.yellow"
                      item-title="nro"
                      item-value="abbr"
                      empty-text="sin datos"
                      :items="optionsYellow"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.red"
                      item-title="isRed"
                      item-value="abbr"
                      :items="optionsRedCard"
                      empty-text="sin datos"
                      name="isRed"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
              </tr>
            </template>
            <template #bottom></template>
          </v-data-table>
          <v-data-table
              :key="tableOptions.tableKey"
              density="default"
              :headers="headers"
              :items="visitTeam"
              :loading="tableOptions.loading"
              hide-footer
              v-if="visitTeam !== '' && !isLocal"
          >
            <template v-slot:item="{ item, index }">
              <tr :class="{ 'even-row': index % 2 === 0 }">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.goal"
                      item-title="nro"
                      item-value="abbr"
                      :items="optionsNumberGoals"
                      empty-text="sin datos"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.yellow"
                      empty-text="sin datos"
                      item-title="nro"
                      item-value="abbr"
                      :items="optionsYellow"
                      name="nro"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
                <td>
                  <VInlineSelect
                      v-model="item.red"
                      empty-text="sin datos"
                      item-title="isRed"
                      item-value="abbr"
                      :items="optionsRedCard"
                      name="isRed"
                      return-object
                      :hide-cancel-icon="true"
                      :hide-save-icon="true"
                  />
                </td>
              </tr>
            </template>
            <template #bottom></template>
          </v-data-table>
          <v-card-actions v-if="!isLocal && isPlayoff">
            <v-row>
              <v-col md="4" class="align-start col-padding-top">Resultado penales:</v-col>
              <v-col md="1" class="align-start col-padding-top">{{ local }}</v-col>
              <v-col md="1" align="center">
                <VInlineSelect
                    v-model="formattedPenaltiesTeamA"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    name="nro"
                    empty-text="sin datos"
                    return-object
                    table-field
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
              <v-col md="1">-</v-col>
              <v-col md="1">
                <VInlineSelect
                    v-model="formattedPenaltiesTeamB"
                    item-title="nro"
                    item-value="abbr"
                    :items="optionsNumberGoalsResult"
                    name="nro"
                    return-object
                    empty-text="sin datos"
                    table-field
                    :hide-cancel-icon="true"
                    :hide-save-icon="true"
                />
              </v-col>
              <v-col md="1">{{ visitante }}</v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col offset-sm="0" offset-md="3" md="6" class="horizontal-gradient ">
        <v-col>
          <v-btn variant="flat" elevation="2" density="comfortable" @click="getModifiedItems" v-if="isLocal">continuar
          </v-btn>
          <v-btn variant="outlined" class="background-btn" density="comfortable" @click="saveMatchReport"
                 v-if="!isLocal">finalizar reporte partido
          </v-btn>
        </v-col>
        <v-col v-if="!isLocal">
          <v-btn variant="outlined" class="background-btn" density="comfortable" @click="goBack" v-if="!isLocal">atras
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
  <ErrorAlert v-model:message="errorMessage" :type="errorType"/>
</template>

<script setup>
/* eslint-disable */
import {reactive, ref, onMounted, computed} from 'vue';
import {useMatchtore} from "@/store/match";
import {useRoute, useRouter} from "vue-router";
import ErrorAlert from "@/components/messageUser/ErrorAlert.vue";
import {useErrorStore} from "@/models/useErrorHandling";
import {id} from "vuetify/locale";

const errorStore = useErrorStore();
const isLocal = ref(true)
const matchStore = useMatchtore();
const route = useRoute();
const router = useRouter();
const matchId = route.params.matchId;
const tournamentId = route.params.tournamentId;
const local = ref(null);
const visitante = ref(null);
const fechaNro = ref(null);
const localTeam = ref('');
const visitTeam = ref(null);
const tournament = ref(null);
const idTeamA = ref(null);
const idTeamB = ref(null);
const errorType = ref('Error carga resultados');
const headers = ref([
  {
    align: 'center',
    key: 'name',
    title: 'Nombre',
    width: 325,
  },
  {
    align: 'start',
    key: 'goal',
    title: 'Goles',
  },
  {
    align: 'start',
    key: 'yellow',
    title: 'Amarillas',
  },
  {
    align: 'center',
    key: 'red',
    title: 'Rojas',
  },
]);

const numFase = ref("");
const modifiedItems = ref([]);
const localTeamModified = ref([]);
const playersGoalsTeamA = ref([]);
const yellowsTeamA = ref([]);
const redTeamA = ref([]);
const redTeamB = ref([]);
const playersGoalsTeamB = ref([]);
const yellowsTeamB = ref([]);
const goalsTeamA = ref('');
const goalsTeamB = ref('');
const penaltiesTeamA = ref('');
const penaltiesTeamB = ref('');
const optionsRedCard = reactive([
  {
    abbr: false,
    isRed: 'No',
  },
  {
    abbr: true,
    isRed: 'Si',
  },
  {
    abbr: 'Sin datos',
    isRed: null,

  }
]);
const optionsYellow = reactive([
  {
    abbr: '1',
    nro: 1,
  },
  {
    abbr: '2',
    nro: 2,
  },
  {
    abbr: 'sin datos',
    nro: 'sin datos',
  }
]);
const isPlayoff = ref(false);

const formattedGoalsTeamA = computed({
  get() {
    return goalsTeamA.value.hasOwnProperty('abbr') ? goalsTeamA.value.abbr : goalsTeamA.value;
  },
  set(newValue) {
    goalsTeamA.value = newValue;
  }
});
const formattedGoalsTeamB = computed({
  get() {
    return goalsTeamB.value.hasOwnProperty('abbr') ? goalsTeamB.value.abbr : goalsTeamB.value;
  },
  set(newValue) {
    goalsTeamB.value = newValue;
  }
});

const formattedPenaltiesTeamA = computed({
  get() {
    return penaltiesTeamA.value.hasOwnProperty('abbr') ? penaltiesTeamA.value.abbr : penaltiesTeamA.value;
  },
  set(newValue) {
    penaltiesTeamA.value = newValue;
  }
});
const formattedPenaltiesTeamB = computed({
  get() {
    return penaltiesTeamB.value.hasOwnProperty('abbr') ? penaltiesTeamB.value.abbr : penaltiesTeamB.value;
  },
  set(newValue) {
    penaltiesTeamB.value = newValue;
  }
});
const optionsNumberGoalsResult = reactive([
  {abbr: 'sin datos', nro: 'sin datos'},
  ...Array.from({length: 101}, (_, i) => ({abbr: i.toString(), nro: i}))
]);
const optionsNumberGoals = reactive([
  {abbr: 'sin datos', nro: 'sin datos'},
  ...Array.from({length: 100}, (_, i) => ({abbr: (i + 1).toString(), nro: i + 1}))
]);

onMounted(async () => {
  const {success, status, content} = await matchStore.dispatchGetInfoMatch(matchId, tournamentId);
  if (!success) {
    console.log("Ups, something happened 🙂");
    console.log("Api status ->", status);
    return;
  }
  local.value = content.name_1;
  localTeam.value = content.players1;
  visitante.value = content.name_2;
  visitTeam.value = content.players2;
  fechaNro.value = content.number_of_date;
  tournament.value = content.id_tournament;
  idTeamA.value = content.id_team_1;
  idTeamB.value = content.id_team_2;
  numFase.value = content.current_phase;
  isPlayoff.value = content.current_schema == "playoff";
});
const tableOptions = reactive({
  itemsPerPage: 10,
  loading: false,
  sortBy: [{key: 'name', order: 'asc'}],
  tableKey: new Date().getUTCMilliseconds(),
});

const getModifiedItems = () => {
  localTeamModified.value = localTeam.value.filter((item) => {
    return item.yellow.nro !== '' || item.goal.nro !== '';
  });

  playersGoalsTeamA.value = localTeam.value.reduce((acc, item) => {
    const goalCount = parseInt(item.goal.nro, 10) || 0;
    return [...acc, ...Array(goalCount).fill(item.id)];
  }, []);

  yellowsTeamA.value = localTeam.value.reduce((acc, item) => {
    const yellowCount = parseInt(item.yellow.nro, 10) || 0;
    return [...acc, ...Array(yellowCount).fill(item.id)];
  }, []);

  redTeamA.value = localTeam.value.reduce((acc, item) => {
    const redCount = (item.red ? 1 : 0) || 0;
    return [...acc, ...Array(redCount).fill(item.id)];
  }, []);
  let isOK = validateTeamInfo(goalsTeamA, playersGoalsTeamA);
  if (!isOK) {
    return
  }
  isLocal.value = false;
};

const goBack = () => {
  isLocal.value = true;
}

async function saveMatchReport () {
  playersGoalsTeamB.value = visitTeam.value.reduce((acc, item) => {
    const goalCount = parseInt(item.goal.nro, 10) || 0;
    return [...acc, ...Array(goalCount).fill(item.id)];
  }, []);

  yellowsTeamB.value = visitTeam.value.reduce((acc, item) => {
    const yellowCount = parseInt(item.yellow.nro, 10) || 0;
    return [...acc, ...Array(yellowCount).fill(item.id)];
  }, []);

  redTeamB.value = visitTeam.value.reduce((acc, item) => {
    const redCount = (item.red ? 1 : 0) || 0;
    return [...acc, ...Array(redCount).fill(item.id)];
  }, []);
  const dataForm = fillData();
  let isOK = validateTeamInfo(goalsTeamB, playersGoalsTeamB);
  if (!isOK) {
    return;
  } else {
    const response = await matchStore.dispatchAddMatch(dataForm);
	console.log("resultado ");
	console.log(response);
    if (response.success) {
      router.push({
        name: 'TournamentDetails',
        params: {
          id: tournamentId
        },
      })
    }
  }
}

const fillData = () => {
  let winner = goalsTeamA.value.nro > goalsTeamB.value.nro ? idTeamA.value : idTeamB.value; // y si es empate ???
  winner = goalsTeamA.value.nro === goalsTeamB.value.nro ? 0 : winner;
  let penaltiesResult = '';
  if (winner === 0) {
    penaltiesResult = penaltiesTeamA.value.nro + '-' + penaltiesTeamB.value.nro;
  }
  const dataForm = {
    "matches": [
      {
        "id_1": idTeamA.value,
        "id_2": idTeamB.value,
        "idTournament": tournament.value,
        "numFase": numFase.value, //indicar si es liga o playoff 1 o 2 respectivamente
        "idWinner": winner,
        "stats": {
          "goles_1": playersGoalsTeamA.value,
          "goles_2": playersGoalsTeamB.value,
          "res": [parseInt(goalsTeamA.value.nro), parseInt(goalsTeamB.value.nro)],
          "amarillas_1": yellowsTeamA.value,
          "amarillas_2": yellowsTeamB.value,
          "rojas_1": redTeamA.value,
          "rojas_2": redTeamB.value,
          "cantidad_fechas_suspension_rojas_1": [], // si no vienen definidas le mandamos 1 fecha de suspension
          "cantidad_fechas_suspension_rojas_2": [],
          "penales": penaltiesResult
        }
      }
    ]
  };
  return dataForm;
}

const validateTeamInfo = (goalsTeam, playersTeam) => {
  let message = ''
  if (goalsTeam.value === '') {
    message = "No se registraron goles. ";
  }
  if (playersTeam.value.length < goalsTeam.value) {
    message += "Los goles generales no coinciden con los goles de los jugadores. ";
  }
  if (!isLocal.value && goalsTeamA.value === goalsTeamB && penaltiesTeamA.value !== penaltiesTeamB.value && numFase === 2) {
    message += "El resultado fue un empate, debe completar los penales"
  }
  if (message !== '') {
    errorStore.handleError(message)
    return false;
  }
  return true;
}
</script>
<style>
.horizontal-gradient {
  background: linear-gradient(to left, blue, skyblue);
}

.even-row {
  background-color: #f9f3f3; /* Color de fondo para filas pares */
}

.background-btn {
  background-color: whitesmoke !important;
}

.background-btn i:hover {
  background-color: #3C618B !important;
}

.background-btn::before {
  background-color: #3C618B !important;
}

.col-padding-top {
  padding-top: 1.7% !important;
}

@media (max-width: 600px) {
  /* Ajusta el ancho máximo según tu diseño */
  .padding-left-sm {
    /* Estilos adicionales para pantallas sm y más pequeñas */
    padding-left: 0.1% !important; /* Otra forma de ocultar el elemento */
  }

  .col-padding-top {
    padding-top: 0px !important;
  }
}
</style>