<template>
<div class="create-tournament-form">
	<h2>Create Tournament</h2>
	<form @submit.prevent="submitForm">
	<div class="form-group">
		<label for="name">Tournament Name:</label>
		<input type="text" v-model="tournament.name" id="name" required />
	</div>

	<div class="form-group">
		<label for="sport">Sport:</label>
		<select v-model="tournament.sport" id="sport" @change="handleSportChange">
			<option value="futbol">Futbol</option>
			<option value="tenis">Tenis</option>
			<option value="padel">Padel</option>
		</select>
	</div>


	<div class="form-group">
		<label for="startDate">Start Date:</label>
		<input type="date" v-model="tournament.startDate" id="startDate"  />
	</div>

	<div class="form-group">
		<label for="endDate">End Date:</label>
		<input type="date" v-model="tournament.endDate" id="endDate"  />
	</div>

	<div class="form-group">
		<label for="teams">Number of Teams:</label>
		<input type="number" v-model="tournament.numOfTeams" id="teams" required min="2" />
	</div>

	<div class="form-group">
		<label>Tournament Format:</label>
		<div class="format-options">
			<div
				v-for="option in filteredFormatOptions"
				:key="option.value"
				:class="['format-option', { selected: tournament.format === option.value }]"
				@click="selectFormat(option.value)"
			>
				<span>{{ option.label }}</span>
			</div>
		</div>
	</div>

	<div v-if="tournament.format === 'group'" class="form-group">
		<label for="groups">Number of Groups:</label>
		<input type="number" v-model="tournament.groups" id="groups" required min="2" />
	</div>

	<div v-if="tournament.hasTwoPhases" class="form-group">
		<label for="qualifiedTeams">Teams Qualifying for Next Phase:</label>
		<input type="number" v-model="tournament.qualifiedTeams" id="qualifiedTeams" required min="1" />
	</div>
	<div v-if="tournament.isGroup" class="form-group">
		<label for="qualifiedTeams">Number of groups:</label>
		<input type="number" v-model="tournament.numberOfGroups" id="qualifiedTeams" required min="1" />
	</div>
	
<!-- Selección de equipos -->
<h3>Choose team:</h3>
	<v-autocomplete
    v-model="newTeamName"
    :items="filteredTeams"
    item-value="name"
    item-title="name"
    label="Select team"
    clearable
    @input="filterTeams"
	class="custom-autocomplete"
    @keydown.enter.prevent="addTeam"
  />

<button type="button" class="submit-button" @click="addTeam">Add Team</button>
<br>
<div v-if="tournament.teams.length" class="teams-list">
	<h3>Teams:</h3>
	<ul>
	<li v-for="(team, index) in tournament.teams" :key="team.id">
		{{ team.name }}
		<button type="button" @click="removeTeam(index)">Remove</button>
	</li>
	</ul>
</div>


	<button type="submit" class="submit-button">Create Tournament</button>
	</form>
</div>
</template>

<script>

import axios from 'axios';
//import http from "@/services/api";
import {useTeamStore} from "@/store/team";
import { useAuthStore } from "@/store/auth";


export default {

data() {
	return {
	tournament: {
		name: '',
		startDate: '',
		endDate: '',
		teams: [],
		format: '',
		groups: null,
		qualifiedTeams: null,
		hasTwoPhases: false,
		isGroup: false,
		numberOfGroups: 0,
		numOfTeams: 0,
		newTeams: [],
		sport: 'futbol',
	},
	newTeamName: '',
	filteredTeams: [],
	allTeams: [], // Lista que vendrá de la API
	formatOptions: [
		{ label: 'League', value: 'league', image: 'league-icon.png' },
		{ label: 'Playoff', value: 'playoff', image: 'playoff-icon.png' },
		{ label: 'Group + Playoff', value: 'group+playoff', image: 'group-playoff-icon.png' },
		{ label: 'League + Playoff', value: 'league+playoff', image: 'league-playoff-icon.png' },
	],
	filteredFormatOptions: [], // Opciones filtradas por deporte
	};
},
methods: {
	async fetchTeams() {
		// Simulando llamada a la API (reemplazar con una llamada real)

		const teamStore = useTeamStore();
		const response = await teamStore.dispatchGetTeams();
		this.allTeams = response.content;
		this.filteredTeams = this.allTeams; // Mostrar todos los equipos inicialmente
		console.log("muestro equipos " , this.filteredTeams);
	},
	filterTeams() {
		if (!this.newTeamName){
			return ; 
		}
	const query = this.newTeamName.toLowerCase();
      if (query) {
        this.filteredTeams = this.allTeams.filter(team =>
          team.name.toLowerCase().includes(query)
        );
      } else {
        this.filteredTeams = this.allTeams; // Si no hay texto, mostrar todos
      }
    },
    showAllTeams() {
      this.filteredTeams = this.allTeams; // Mostrar todos los equipos cuando se hace foco en el input
    },
    addTeam() {
      const selectedTeam = this.allTeams.find(
        team => team.name === this.newTeamName
      );
      if (selectedTeam && !this.tournament.teams.includes(selectedTeam)) {
        this.tournament.teams.push(selectedTeam);
        this.newTeamName = '';
		this.filteredTeams = this.allTeams;
      }
    },
    removeTeam(index) {
      this.tournament.teams.splice(index, 1);
    },
	handleSportChange() {
			// Filtra las opciones de formato según el deporte seleccionado
			const sport = this.tournament.sport;
			if (sport === 'tenis' || sport === 'padel') {
				// Eliminar opciones 'League' y 'League + Playoff'
				this.filteredFormatOptions = this.formatOptions.filter(option =>
					!option.value.includes('league')
				);
			} else {
				// Mostrar todas las opciones para futbol
				this.filteredFormatOptions = this.formatOptions;
			}
			// Reiniciar el formato seleccionado
			this.tournament.format = '';
	},
	
	async submitForm() {
		// Validación: que la cantidad de equipos sea igual al número de equipos ingresado
		if (this.tournament.teams.length !== this.tournament.numOfTeams) {
			alert(`La cantidad de equipos seleccionados (${this.tournament.teams.length}) no coincide con el número ingresado (${this.tournament.numOfTeams}).`);
			return;
		}

		// Validación: si el formato es de grupos, asegurar que la división de equipos y clasificados sea un número entero
		if (this.tournament.format.includes('group')) {
			// Equipos por grupo

			const teamsPerGroup = this.tournament.numOfTeams / this.tournament.numberOfGroups;
			if (!Number.isInteger(teamsPerGroup)) {
				alert(`La cantidad de equipos (${this.tournament.numOfTeams}) no se puede dividir en grupos iguales de ${this.tournament.numberOfGroups} grupos.`);
				return;
			}

			// Clasificados por grupo
			const qualifiedPerGroup = this.tournament.qualifiedTeams / this.tournament.numberOfGroups;
			if (!Number.isInteger(qualifiedPerGroup)) {
				alert(`La cantidad de equipos clasificados (${this.tournament.qualifiedTeams}) no se puede dividir en partes iguales entre ${this.tournament.numberOfGroups} grupos.`);
				return;
			}
		}

		// Definir número de fases según formato
		let number_of_phases = 1;
		if (this.tournament.format == "group+playoff" || this.tournament.format == "league+playoff") {
			if (this.tournament.qualifiedTeams <= 1) {
				alert(`La cantidad de equipos clasificados a la proxima ronda tiene q ser mayor que 1`);
				return;
			}
			number_of_phases = 2;
		}

		let teams_ids = [];
		let ids_zero = [];
		let teams = [];

		this.tournament.teams.forEach(element => {
			teams_ids.push(element.id);
			ids_zero.push(0);
		});

		if (this.tournament.format == "playoff") {
			teams = [teams_ids, ids_zero];
		} else {
			teams = teams_ids;
		}

		// Parámetros a enviar
		const params = {
			"name_of_tournament": this.tournament.name,
			"date_begin": this.tournament.startDate,
			"date_end": this.tournament.endDate,
			"number_of_stadiums": "3",
			"time_range": "TODO",
			"sport": this.tournament.sport,
			"number_of_phases": number_of_phases,
			"schema_value": this.tournament.format,
			"match_time": "60",
			"schema_conf": {
				"f1": {
				"preclasificados": 0,
				"with_preclasifications_number": true,
				"cross": "ida_vuelta",
				"classification_strategy": "primeros_vs_ultimos",
				"cuantos_clasifican": this.tournament.qualifiedTeams,
				"cant_groups": this.tournament.numberOfGroups,
				"teams_by_group": this.tournament.numOfTeams / this.tournament.numberOfGroups,
				}
			},
			"teams": teams
		};

		// Petición con Axios
		const token = useAuthStore().token; // Obtener el token del store

		const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + 'tournament', params, {
			headers: {
				'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
				'Content-Type': 'application/json'
			}
		});
		if (response.status === 200) {
			alert("¡Torneo creado exitosamente!");
			this.resetForm();
		}
}
,
	selectFormat(format) {
		this.tournament.format = format;
		this.tournament.hasTwoPhases = format.includes('+');
		this.tournament.isGroup = format.includes('group');
	},
    resetForm() {
      this.tournament = {
        name: '',
        startDate: '',
        endDate: '',
        teams: [],
        format: '',
        groups: null,
        qualifiedTeams: null,
        hasTwoPhases: false,
        isGroup: false,
        numberOfGroups: 0,
        numOfTeams: 0,
      };
      this.newTeamName = '';
      this.filteredTeams = [];
    }
},
mounted() {
	this.fetchTeams(); // Llamada a la API al cargar el componente
	this.handleSportChange();
},
};
</script>

<style scoped>
/* Estilos previos */
.create-tournament-form {
max-width: 600px;
margin: auto;
padding: 20px;
background-color: #f9f9f9;
border-radius: 8px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
margin-bottom: 20px;
}

label {
display: block;
font-weight: bold;
margin-bottom: 5px;
}

input {
width: 100%;
padding: 10px;
border: 1px solid #ddd;
border-radius: 4px;
}

.format-options {
display: flex;
flex-direction: column;
gap: 10px;
}

.format-option {
display: flex;
align-items: center;
padding: 10px;
border: 2px solid #ddd;
border-radius: 8px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.format-option.selected {
background-color: #e0f7fa;
border-color: #00acc1;
}

.format-image {
width: 40px;
height: 40px;
margin-right: 10px;
}

.submit-button {
display: block;
width: 100%;
padding: 12px;
background-color: #00acc1;
color: #fff;
border: none;
border-radius: 8px;
font-size: 16px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.submit-button:hover {
background-color: #007c91;
}

.teams-list ul {
list-style: none;
padding: 0;
}

.teams-list li {
display: flex;
justify-content: space-between;
padding: 10px;
background-color: #f1f1f1;
border-radius: 5px;
margin-bottom: 5px;
}

.teams-list button {
background-color: #e57373;
color: #fff;
border: none;
padding: 5px 10px;
border-radius: 5px;
cursor: pointer;
}

.teams-list button:hover {
background-color: #ef5350;
}

.custom-autocomplete .v-list-item--active {
  background-color: #A8E6CF !important; /* Verde pastel */
  color: black ;  /* Color del texto */
}

.create-tournament-form {
	max-width: 600px;
	margin: auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
	margin-bottom: 20px;
}

label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
}

input, select {
	width: 100%;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.format-options {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.format-option {
	padding: 10px;
	border: 2px solid #ddd;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.format-option.selected {
	background-color: #4caf50;
	color: white;
	border-color: #4caf50;
}

button.submit-button {
	background-color: #4caf50;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
}

button.submit-button:hover {
	background-color: #45a049;
}

.teams-list ul {
	list-style-type: none;
	padding-left: 0;
}

.teams-list li {
	margin-bottom: 10px;
}

.teams-list button {
	background-color: #f44336;
	color: white;
	border: none;
	padding: 5px 10px;
	border-radius: 4px;
	cursor: pointer;
}

.teams-list button:hover {
	background-color: #d32f2f;
}

.custom-autocomplete .v-autocomplete__content {
	max-height: 200px;
	overflow-y: auto;
}
</style>
