<template>
    <div class="match-details">
        <h2>Match Details</h2>
        <div v-if="match">
            <div class="team-section">
                <h3>{{ match.name_team_1 }}</h3>
                <div class="score">{{ match.stats.res ? match.stats.res.split('-')[0] : '' }}</div>
            </div>
            <div class="vs">vs</div>
            <div class="team-section">
                <h3>{{ match.name_team_2 }}</h3>
                <div class="score">{{ match.stats.res ? match.stats.res.split('-')[1] : '' }}</div>
            </div>

            <div v-if = "match.stats.sport == 'futbol'" class="stats-section">
                <h4>Team {{ match.name_team_1 }} Statistics</h4>
                <div class="stat">
                    <strong>Goals:</strong>
                    <ul>
                        <li v-for="(goal, index) in match.stats.goles_1_names" :key="index">
                            ⚽ {{ goal }}
                        </li>
                    </ul>
                </div>
                <div class="stat">
                    <strong>Yellow Cards:</strong>
                    <ul v-if="match.stats.amarillas_1_names && match.stats.amarillas_1_names.length">
                        <li v-for="(player, index) in match.stats.amarillas_1_names" :key="index" class="yellow">
                            🟨 {{ player }}
                        </li>
                    </ul>
                </div>
                <div class="stat">
                    <strong>Red Cards:</strong>
                    <ul v-if="match.stats.rojas_1_names && match.stats.rojas_1_names.length">
                        <li v-for="(player, index) in match.stats.rojas_1_names" :key="index" class="red">
                            🟥 {{ player }}
                        </li>
                    </ul>
                </div>

                <h4>Team {{ match.name_team_2 }} Statistics</h4>
                <div class="stat">
                    <strong>Goals:</strong>
                    <ul>
                        <li v-for="(goal, index) in match.stats.goles_2_names" :key="index">
                            ⚽ {{ goal }}
                        </li>
                    </ul>
                </div>
                <div class="stat">
                    <strong>Yellow Cards:</strong>
                    <ul v-if="match.stats.amarillas_2_names && match.stats.amarillas_2_names.length">
                        <li v-for="(player, index) in match.stats.amarillas_2_names" :key="index" class="yellow">
                            🟨 {{ player }}
                        </li>
                    </ul>
                </div>
                <div class="stat">
                    <strong>Red Cards:</strong>
                    <ul v-if="match.stats.rojas_2_names && match.stats.rojas_2_names.length">
                        <li v-for="(player, index) in match.stats.rojas_2_names" :key="index" class="red">
                            🟥 {{ player }}
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else >
                Resultado : {{ formatTennisResult(match.res) }}
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useAuthStore } from "@/store/auth";


export default {
    name: 'ShowMatch',
    props: {
        tournamentId: {
            type: String,
            required: true
        },
        matchId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            match: null
        };
    },
    created() {
        this.fetchMatchDetails();
    },
    methods: {
        
        async fetchMatchDetails() {
            const token = useAuthStore().token; // Obtener el token del store

            axios
                const response = await axios.get(
				process.env.VUE_APP_API_ENDPOINT + `show_match/${this.tournamentId}/${this.matchId}`, {
					headers: {
						'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
						'Content-Type': 'application/json'
					},
				}
			);
            this.match = response.data;
        
        },

        formatTennisResult(resString) {
            try {
                // Parsear el string JSON a un objeto
                const resObject = JSON.parse(resString);
                // Asegurarse de que existe la propiedad 'res' y es un array
                if (resObject.res && Array.isArray(resObject.res)) {
                return resObject.res.join(', '); // Formatear como '6-4, 6-3'
                }
                return ''; // Retorna vacío si no tiene el formato esperado
            } catch (e) {
                console.error('Error al parsear el JSON:', e);
                return ''; // Si falla el parseo, retornar vacío
            }
        }


    }
};
</script>

<style scoped>
.match-details {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f4f6f8;
    max-width: 600px;
    margin: 0 auto;
}

h2 {
    text-align: center;
    color: #4caf50;
    font-weight: bold;
}

.team-section {
    display: inline-block;
    width: 45%;
    text-align: center;
    margin-bottom: 10px;
}

.vs {
    display: inline-block;
    width: 10%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.score {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.stats-section {
    margin-top: 20px;
}

.stat {
    margin-bottom: 10px;
}

.stat strong {
    display: block;
    text-align: left; /* Alinea a la izquierda los títulos */
    margin-bottom: 5px;
    font-size: 16px;
}

.stat ul {
    padding-left: 0;
    list-style: none;
}

.stat li {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

h4 {
    margin-top: 20px;
    color: #2196f3;
    text-align: left; /* Alinea a la izquierda el título de cada sección */
}

.yellow {
    color: #fbc02d;
}

.red {
    color: #d32f2f;
}
</style>
