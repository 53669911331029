import http from "../api";

async function login(username, password) {
    return await http.post('login', { 'email':username, 'password':password });

    // try {
    //     const response = await http.post('login', { 'email':username, 'password':password });
    //     http.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    //     return {
    //         success: true,
    //         msg: 'OK',
    //     }
    // } catch (error) {
    //     console.error(error);
    //     return {
    //         success: false,
    //         msg: error,
    //     }
    // }
}

export default {

    login,
};