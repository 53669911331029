import {defineStore} from "pinia";
import {ref} from 'vue';
import {API} from "@/services";

export const useTournamentStore = defineStore("tournamentStore", () => {


    const tournaments = ref([]);
    const aTournament = ref([]);
    const bestScorerOfTournament = ref([]);
    const matches_phase_1 = ref([]);
    const matches_phase_2 = ref([]);
    const teamsOfTournament = ref([]);
    function initTournament(data) {
        tournaments.value = data;
    }

    function initAtournament(data) {
        aTournament.value = data;
    }

    function initbestScorerOfTournament(data) {
        bestScorerOfTournament.value = data;
    }

    function initMatchesOfTournament(data) {
        matches_phase_1.value = data.phase_1;
        if (data.phase_2 !== undefined) {
            matches_phase_2.value = data.phase_2;
        }
    }

    function initTeamsOfTournament(data) {
        teamsOfTournament.value = data;
    }

    async function dispatchGetTournaments() {
        try {
            const {status, data} = await API.tournaments.getTournaments();

            if (status === 200) {
                initTournament(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchATournament(id) {
        try {
            const {status, data} = await API.tournaments.getAtournament(id);
            if (status === 200) {
                initAtournament(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function getBestScorerOfTournament(id) {
        try {
            const {status, data} = await API.tournaments.getBestScorerOfAtournament(id);
            if (status === 200) {
                initbestScorerOfTournament(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function getMatchesOfATournament(id){
        try {
            const {status, data} = await API.tournaments.getMatchesOfAtournament(id);
            if (status === 200) {
                initMatchesOfTournament(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function getTeamsOfATournament(id){
        try {
            const {status, data} = await API.tournaments.geTeamsOfAtournament(id);
            if (status === 200) {
                initTeamsOfTournament(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status, // Optional chaining to handle cases where _error.response might be undefined
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }


    return {
        tournaments,
        dispatchGetTournaments,
        dispatchATournament,
        aTournament,
        getBestScorerOfTournament,
        getMatchesOfATournament,
        matches_phase_1,
        matches_phase_2,
        getTeamsOfATournament
    };

});