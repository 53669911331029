import { ref } from 'vue';
import {defineStore} from "pinia";

export const useErrorStore = defineStore("errorStore", () => {

    const showError = ref(false);
    const errorMessage = ref('Datos incompletos');
    const errorType = ref('error');

    function handleError(message) {
        showError.value = true;
        errorMessage.value = message;
    }

    return {
        showError,
        errorMessage,
        errorType,
        handleError
    };
})