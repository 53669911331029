import {defineStore} from "pinia";
import {ref} from 'vue';
import {API} from "@/services";

export const useMatchtore = defineStore("matchStore", () => {


    const matchs = ref([]);


    function initMatchs(data) {
        const players_one = [];
        const players_two = [];
        for (let i = 0; i < data.players1.length; i++) {
            const dataPlayer = {'id': data.players1[i].id,'name':data.players1[i].name,'yellow':'', 'goal': '', 'red':false}
            players_one.push(dataPlayer);
        }
        for (let i = 0; i < data.players2.length; i++) {
            const dataPlayer = {'id': data.players2[i].id, 'name':data.players2[i].name,'yellow':'', 'goal': '', 'red':false}
            players_two.push(dataPlayer);
        }
        data.players1 = players_one;
        data.players2 = players_two;
        matchs.value = data;

    }


    async function dispatchGetMatch() {
        try {
            const {status, data} = await API.matchs.getMatch();

            if (status === 200) {
                initMatchs(data);
                return {
                    success: true,
                    content: data.content,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchGetInfoMatch(matchId, tournamentId) {
        try {
            const {status, data} = await API.matchs.getInfoMatch(matchId, tournamentId);

            if (status === 200) {
                initMatchs(data);
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchAddMatch(aMatch) {
        try {
            const {status} = await API.matchs.addMatch(aMatch);
            if (status === 200) {
                return {
                    success: true,
                    content: null,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    async function dispatchPdfMatch(aMatch, aTournamentId) {
        try {
            const {status, data} = await API.matchs.getPdfMatch(aMatch, aTournamentId);

            if (status === 200) {
                return {
                    success: true,
                    content: data,
                };
            }
        } catch (error) {
            const _error = error;
            console.log(error);
            return {
                success: false,
                status: _error.response?.status,
                content: null,
            };
        }
        return {
            success: false,
            content: null,
            status: 400,
        };
    }

    return {
        matchs,
        initMatchs,
        dispatchGetMatch,
        dispatchGetInfoMatch,
        dispatchAddMatch,
        dispatchPdfMatch,
    };

});