<template>

    <v-row class="tabs">
      <v-col :key="index" v-for="(tab, index) in tabs" align="center">
      <button
          :key="index"
          :class="{ active: selectedTab === index }"
          @click="selectedTab = index"
          class="tab-button"
      >
        {{ tab }}
      </button>
      </v-col>
    </v-row>

	<v-row class="tab-content" align="center">
	<v-col v-if="selectedTab === 0" class="width-sm">
		<!-- Tabla de la Liga -->
		<div v-if = "champion != ''" class="champion">
			<h2>Champion: {{ champion }}</h2>
		</div>
    <h2 class="table-title">Tournament League Table</h2>
    <v-data-table
        :key="tableOptions.tableKey"
        density="default"
        :headers="headers"
        :items="groups"
        :loading="tableOptions.loading"
        color="primary"
		class = "league-table"
    >
      <template v-slot:header="{ header }">
        <tr style="background-color: rgb(24, 103, 192) !important;">
          <th>
            <span>{{header.title}}</span>
          </th>
        </tr>

      </template>

      <template v-slot:item="{ item, index}">
        <tr :class="{ 'even-row': index % 2 === 0 }">
          <td>{{ item.position }}</td>
          <td>{{ item.name }}</td>
          <td v-if="item.stats">{{ item.stats.points  }}</td>
          <td v-else>0</td>
          <td v-if="item.stats">{{ item.stats.pj }}</td>
          <td v-else>0</td>
          <td v-if="item.stats">{{ item.stats.partidos_ganados }}</td>
          <td v-else>0</td>
          <td v-if="item.stats">{{ item.stats.partidos_empatados }}</td>
          <td v-else>0</td>
          <td v-if="item.stats">{{ item.stats.partidos_perdidos }}</td>
          <td v-else>0</td>
          <td v-if="item.stats" v-show="!isDesktop">{{ item.stats.goles }}</td>
          <td v-else>0</td>
          <td v-if="item.stats" v-show="!isDesktop">{{ item.stats.goles_contra }}</td>
          <td v-else>0</td>
          <td v-if="item.stats" v-show="!isDesktop">{{ item.stats.goles - item.stats.goles_contra }}</td>
          <td v-else>0</td>
        </tr>
      </template>
      <template #bottom></template>

    </v-data-table>
		<FixturePlayoff v-if="this.matches_phase_2.length > 0"
			:idT="this.idT" :info="this.info" :matches="this.matches_phase_2" :champion="this.champion" />  
	</v-col>

	<v-row v-if="selectedTab === 1 && sport === 'futbol'">
		<!-- Goleadores -->
		<MaxScorers :idTournament="this.idT" />
	</v-row>

	<v-row v-if="(selectedTab === 2 && sport === 'futbol') || (selectedTab === 1 && sport !== 'futbol')">
		<!-- Partidos divididos por fecha -->
		<GrillaMatchesByDateLeague :matches="this.matches_phase_1" />
		<GrillaMatchesByDatePlayoff v-if="this.matches_phase_2.length > 0"
			:matches="matches_phase_2" />
	</v-row>
	<v-row v-if="(selectedTab === 3 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')">
		<!-- Partidos divididos por fecha -->
		<ShowTeamsOfTournament :tournamentId="this.idT" />
	</v-row>
	</v-row>
</template>

<script>

import GrillaMatchesByDateLeague from './GrillaMatchesByDateLeague.vue';
import GrillaMatchesByDatePlayoff from './GrillaMatchesByDatePlayoff.vue';

import MaxScorers from './MaxScorers.vue';
import FixturePlayoff from './FixturePlayoff.vue';

import ShowTeamsOfTournament from './ShowTeamsOfTournament.vue';
//import {reactive, ref} from "vue";
import { useDisplay } from 'vuetify';

export default {
components: {
	GrillaMatchesByDateLeague,
	MaxScorers,
	FixturePlayoff,
	GrillaMatchesByDatePlayoff,
	ShowTeamsOfTournament,

},
props: ['idT', 'info', 'matches_phase_1', 'matches_phase_2', 'champion', 'classified', 'sport'],
  setup(){
    const { isDesktop } = useDisplay();
    return { isDesktop };
  },
data() {
	return {
    tabs: ['Liga', 'Maximos goleadores', 'Grilla de partidos', 'Equipos'],
    activeTab: 0,
    groups: [],
    scorers: [],
    topScorer: {name: 'Fede', team: 'River', goals: 10},
    selectedTab: 0,
    tableOptions: {
      itemsPerPage: 10,
      loading: false,
      sortBy: [{key: 'name', order: 'asc'}],
      tableKey: new Date().getUTCMilliseconds(),
    },
    headers: [
      {
        align: 'left',
        key: 'position',
        title: 'Pos',
		width: '1px'
      },
      {
        align: 'left',
        key: 'name',
        title: 'Equipo',
		width: '100px'
      },
      {
        align: 'left',
        key: 'points',
        title: 'Pts',
		width: '50px'
      },
      {
        align: 'left',
        key: 'partidos_jugadps',
        title: 'PJ',
		width: '50px'
      },
      {
        align: 'left',
        key: 'partidos_ganados',
        title: 'PG',
		width: '50px'
      },      {
        align: 'left',
        key: 'partidos_empatados',
        title: 'PE',
		width: '50px'
      },      {
        align: 'left',
        key: 'partidos_perdidos',
        title: 'PP',
		width: '50px'
      },      {
        align: 'left d-none d-lg-table-cell',
        key: 'goles',
        title: 'GF',
		width: '50px'
      },{
        align: 'left d-none d-lg-table-cell',
        key: 'goles_contra',
        title: 'GC',
		width: '50px'
      },{
        align: 'left',
        key: 'diferencia_goles',
        title: 'DG',
		width: '50px'
      },


],
}
  ;
},
  computed: {
    showColumn() {
      return true;
    },
    filteredHeaders() {
      return this.headers.filter(header =>
          header.key !== 'goles' || header.key !== 'goles_contra' || header.key !== 'diferencia_goles' || this.isDesktop
      );
    }
  },
  mounted() {
    this.groups = this.info;
    console.log("que onda liga");
	console.log(this.info);
	console.log(this.sport);
	if ( this.sport !== "futbol"){
		this.tabs = ['Liga', 'Grilla de Partidos', 'Jugadores'];
	}
	else{
		this.tabs = ['Liga', 'Máximos Goleadores', 'Grilla de Partidos', 'Equipos'];
	}
}
};
</script>

<style scoped>
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background-color: #0A374D; /* Azul oscuro */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.tab-button {
    padding: 12px 24px;
    margin: 0 10px;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
}

.tab-button.active {
    background-color: #14A38B; /* Verde pastel */
    color: white;
    border: 2px solid #14A38B; /* Verde pastel */
}

.tab-button:hover {
    background-color: #096B80; /* Azul intermedio para hover */
}

/* Estilos para el contenido */
.tab-content {
    background-color: #F9F9F9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Estilos adicionales para la tabla de la liga */
.league-table-container {
max-width: 800px;
margin: 20px auto;
padding: 20px;
background-color: #f9f9f9;
border-radius: 8px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.league-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    margin-top: 10px;
}
.table-title {
text-align: center;
font-size: 24px;
font-weight: 700;
margin-bottom: 20px;
}

.league-table th,
.league-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.league-table th {
background-color: #007BFF;
color: white;
}

.league-table tbody tr:hover {
background-color: #f1f1f1;
}

.league-table tbody tr:nth-child(odd) {
background-color: #f9f9f9;
}


.classified {
    background-color: #d4eddd !important; /* Verde pastel */
}

.custom-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.v-data-table >>> .v-data-table__td {
  background-color: #1976d2 !important; /* Change to your desired color */
  color: white; /* Change text color for better contrast */
}


@media (max-width: 600px) {
  /* Ajusta el ancho máximo según tu diseño */
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background-color: #0A374D; /* Azul oscuro */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .tab-content{
    margin: 0px;
    padding: 0px;
  }
  .width-sm{
    width: 80% !important;
  }
}
</style>
