<template>
<div class="login-container">
	<h2>Iniciar Sesión</h2>
	<form @submit.prevent="loginUser" class="login-form">
		<div class="form-group">
			<label for="email">Email</label>
			<input type="email" v-model="form.email" required class="form-input" />
		</div>
		<div class="form-group">
			<label for="password">Contraseña</label>
			<input type="password" v-model="form.password" required class="form-input" />
		</div>
		<div class="form-group">
			<label for="password">Iniciar sesion</label>
			<v-btn type="submit" class="login-button" :loading="isLoading" :disabled="isLoading">Iniciar Sesión</v-btn>
		</div>
	</form>
</div>
</template>

<script>
//import {API} from "@/services";
//import api from '@/services/api';
import {useAuthStore} from "@/store/auth";


export default {
data() {
	return {
	form: {
		email: "",
		password: "",
	},
    isLoading: false
	};
},
methods: {
	async loginUser() {
    try {
      this.isLoading = true
      const response = await useAuthStore().login(this.form.email, this.form.password);
      if(response.success) {
        this.$router.push({name: 'Tournament'});
        return;
      }
      this.isLoading = false;
    }catch (e) {
      this.isLoading = false;
    }
	},
},
};
</script>

<style scoped>
.login-container {
max-width: 400px;
margin: 0 auto;
padding: 20px;
background-color: #f8f9fa;
border-radius: 10px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
text-align: center;
color: #495057;
margin-bottom: 20px;
}

.login-form {
display: flex;
flex-direction: column;
}

.form-group {
margin-bottom: 15px;
width : 400px; 
}

label {
font-weight: bold;
color: #495057;
margin-bottom: 5px;
display: inline-block;
}

.form-input {
width: 394px;
padding-top: 10px;
padding-bottom: 10px;
border: 1px solid #ced4da;
border-radius: 5px;
background-color: #fff;
color: #495057;
transition: border-color 0.3s ease-in-out;
}

.form-input:focus {
border-color: #80bdff;
outline: none;
}

.login-button {
width : 406px;
height: 37.5px;
background-color: #6c757d;
color: white;
padding: 10px;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease-in-out;
}

.login-button:hover {
background-color: #5a6268;
}

.login-button:active {
background-color: #545b62;
}

.login-container {
background-color: #eef2f7;
}

.login-container .login-button {
background-color: #6ab0a9;
transition: background-color 0.3s ease-in-out;
}

.login-button:hover {
background-color: #569b94;
}
</style>
